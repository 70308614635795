<template>
	<div class="downloadable-logo" :class="$responsive">
		<div class="downloadable-logo__image">
			<img :src="logoImage">	
		</div>
		<div class="downloadable-logo__content">
			<b>{{ logo.description }}</b>
			<br>
				{{ logo.image }}
			<br>
			<a class="lnk download-link" :href="logoImage" target="_blank" download>
				Descargar
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "DownloadableLogo",
		props: [ 'logo' ],
		computed: {
			logoImage() {
				return `../media/logo/${this.logo.image}`;
			}
		}
	}	
</script>

<style scoped>
.downloadable-logo {
	background-color: rgba(255, 255, 255, 0.6);
	margin: 1rem auto;
	max-width: 600px;
	overflow: hidden;
}

.downloadable-logo.mobile {
	max-width: 90vw;
}

.downloadable-logo__image {
	width: 100%;
	max-height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(../assets/alpha-channel-bg.png);
	background-position: 0 0;
	padding: 1rem 0;
}

.downloadable-logo__image img {
	height: auto;
	max-height: 400px;
	max-width: 90%;
}

.downloadable-logo__content {
	text-align: left;
	padding: 1rem 1.25rem;
}

.download-link {
	display: inline-block;
	margin-top: .75rem;
	font-size: .875rem;
}
</style>