<template>
	<div class="brand-logo">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/foo-designer-2.png">
			<h1 class="title" v-markdown:title>
				zione.sport *Logo*
			</h1>
			<p class="view-header__description">
				Descarga las versiones más recientes y oficiales de los logos de zione.sport
			</p>
			<content-section :section="ourLogo.terms">
			</content-section>
		</div>
		<!-- content -->
		<div class="logo-list">
			<downloadable-logo v-for="(logo, logoIndex) in ourLogo.logos"
				:logo="logo"
				:key="logoIndex">
			</downloadable-logo>	
		</div>
	</div>
</template>

<script>
	import content from "@/content.json";
	import downloadableLogo from "@/components/DownloadableLogo";
	import contentSection from "@/components/ContentSection.vue";

	export default {
		name: "BrandLogo",
		components: {
			downloadableLogo,
			contentSection
		},
		data() {
			return {
				ourLogo: content.ourLogo
			}
		}
	}
</script>

<style scoped>
.logo-list > * {
	margin-bottom: 8rem;
}
</style>